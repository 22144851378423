import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./simple-lightbox");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  // top js
  $('.top__slider').slick({
    speed: 500,
    fade: true,
    cssEase: 'linear',
    prevArrow: '',
    nextArrow: '<button class="slider-arrow slider-arrow_next"><i class="fas fa-chevron-right"></i></button>',
    autoplay: true,
  });

  $('.article__images').slick({
    speed: 500,
    fade: true,
    cssEase: 'linear',
    prevArrow: '',
    nextArrow: '<button class="slider-arrow slider-arrow_next slider-arrow_sm"><i class="fas fa-chevron-right"></i></button>',
  });

});
